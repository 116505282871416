import React from 'react';
import { format as formatDate, getDay, getWeek, getMonth } from 'date-fns';
import i18n from '../i18n';

import { HEADER_PROPS } from '../constants';

function getFormattedDate(date) {
	return formatDate(
		new Date(date),
		date.includes('T00:00:00') || date.includes('T23:59:59') ? 'yyyy-MM-dd' : "yyyy-MM-dd '(kl.' HH:mm')'"
	);
}

function getReportLabel(interval) {
	function getDayLabel(day) {
		if (day === 0) return i18n.t('dates.sunday');
		if (day === 1) return i18n.t('dates.monday');
		if (day === 2) return i18n.t('dates.tuesday');
		if (day === 3) return i18n.t('dates.wednessday');
		if (day === 4) return i18n.t('dates.thursday');
		if (day === 5) return i18n.t('dates.friday');
		if (day === 6) return i18n.t('dates.saturday');
	}
	function getMonthLabel(month) {
		if (month === 0) return i18n.t('dates.january');
		if (month === 1) return i18n.t('dates.february');
		if (month === 2) return i18n.t('dates.march');
		if (month === 3) return i18n.t('dates.april');
		if (month === 4) return i18n.t('dates.may');
		if (month === 5) return i18n.t('dates.june');
		if (month === 6) return i18n.t('dates.july');
		if (month === 7) return i18n.t('dates.august');
		if (month === 8) return i18n.t('dates.september');
		if (month === 9) return i18n.t('dates.october');
		if (month === 10) return i18n.t('dates.november');
		if (month === 11) return i18n.t('dates.december');
	}

	const fullDays = Math.round(interval.days);
	let label = '',
		postLabel = '';

	if (fullDays <= 1) {
		label = i18n.t('header.dailyReport');
		postLabel = getDayLabel(getDay(new Date(interval.start)));
	} else if (fullDays === 7 && getDay(new Date(interval.start)) === 1) {
		label = i18n.t('header.weeklyReport');
		postLabel = 'vecka ' + getWeek(new Date(interval.start), { weekStartsOn: 1 });
	} else if (fullDays >= 28 && fullDays <= 31 && new Date(interval.start).getDate() === 1) {
		label = i18n.t('header.monthlyReport');
		postLabel = getMonthLabel(getMonth(new Date(interval.start)));
	} else {
		label = fullDays + i18n.t('header.daysReport');
		if (fullDays < 7)
			postLabel = getDayLabel(getDay(new Date(interval.start))) + i18n.t('header.to') + getDayLabel(getDay(new Date(interval.end)));
	}

	return label + (postLabel ? ' (' + postLabel + ')' : '');
}

/**
 * Draws a header-bar with some text and logos for each page of the report
 * @param {Object} interval : Contains info about the selected time-interval
 */
const validCustomers = ['meliox', 'bastec', 'sandbackens', 'swegon'];
function Header(props) {
	const customer = validCustomers.find(customerName => customerName === props.customer) || 'meliox';
	return (
		<div>
			<div
				style={{
					position: 'fixed',
					width: '100%',
					height: HEADER_PROPS.height,
					//background: HEADER_PROPS.color,
					background:
						customer.toLowerCase() === 'swegon'
							? '#f8f8f8'
							: customer.toLowerCase() === 'bastec'
							  ? '#004b6c'
							  : HEADER_PROPS.color,
					display: 'flex',
					//	boxShadow: '0 0 0.35rem #000a',
					zIndex: '100',
				}}
			>
				{customer === 'bastec' ? (
					<img
						src={require('../assets/BASTEC_logooutline_white.png')}
						style={{ height: '2rem', margin: '1.5rem 0 auto 2.2rem' }}
						alt='Bastec'
					/>
				) : customer === 'sandbackens' ? (
					<img
						src={require('../connect-front/src/assets/sandbackens_logo.png')}
						style={{ height: '1.8rem', margin: '1.6rem 0 auto 2.2rem' }}
						alt='Sandbackens'
					/>
				) : customer === 'swegon' ? (
					<img
						src={require('../assets/swegon_logo_color.svg')}
						style={{ height: '4rem', margin: '0.7rem 0 auto 2.2rem' }}
						alt='Swegon'
					/>
				) : (
					<img
						src={require('../connect-front/src/assets/meliox-logo-white.png')}
						style={{ height: '1.7rem', margin: '1.7rem 0 auto 2.2rem' }}
						alt='Meliox'
					/>
				)}
				<div style={{ color: customer.toLowerCase() === 'swegon' ? 'black' : '#fff', margin: '1rem 0 0 2rem' }}>
					<div style={{ fontSize: '120%', fontWeight: '600' }}>{getReportLabel(props.interval)}</div>
					<div style={{ fontSize: '100%', fontWeight: '400', marginTop: '0.4rem' }}>
						{getFormattedDate(props.startDate) + (props.interval.days >= 1.5 ? ' till ' + getFormattedDate(props.endDate) : '')}
					</div>
				</div>
			</div>
			<div style={{ height: HEADER_PROPS.height }} />
		</div>
	);
}

export default Header;
