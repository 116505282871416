import React, { useState } from 'react';
import AiOverview from '../connect-front/src/components/automation/AiOverview';

export default function AiPage({ properties, aiControls, interval }) {
	function thereAreNullSystems(aiControl) {
		return !aiControl.some(({ system }) => system == null);
	}
	function thereAreEmptyControls(aiControl) {
		return aiControl.length;
	}
	const removedInvalidControls = aiControls.filter(aiControl => thereAreNullSystems(aiControl) && thereAreEmptyControls(aiControl));

	return (
		<>
			{removedInvalidControls.map((aiControl, i) => {
				const property = properties.find(({ locationid }) => String(locationid) === aiControl[0].locationid);
				return (
					<div key={'aipage-' + aiControl[0].index}>
						<AiOverview
							aiControls={aiControl}
							reportFormat
							interval={interval.intervalId}
							property={property || {}}
							systemId={aiControl[0].system}
						/>
						{i !== removedInvalidControls.length - 1 ? <div className='page-break' /> : undefined}
					</div>
				);
			})}
		</>
	);
}
