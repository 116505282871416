import React from 'react';
import { getDataDisplayRange, HistoryLineChart } from '../connect-front/src/components/NivoCharts';
import { useTranslation } from 'react-i18next';

function sortSensors(a, b) {
	function getStreetOrder(sen) {
		return Number(sen.street?.split(' ').pop()) || sen.street;
	}

	const nameA = a.name?.replace(/\W/g, '').toLowerCase();
	const nameB = b.name?.replace(/\W/g, '').toLowerCase();
	if (nameA < nameB) return -1;
	if (nameA > nameB) return 1;
	if (getStreetOrder(a) < getStreetOrder(b)) return -1;
	else return 1;
}

const chartProps = { animate: false, isInteractive: false, useMesh: false };

export default function ReportSensors({ reportSenGrps, graphHeaderStyle, reportSensors, getSensorType, interval }) {
	const { t } = useTranslation();
	return (
		<>
			<h2 style={{ ...graphHeaderStyle, marginLeft: '3.0rem', marginBottom: '-0.5rem' }}>{t('document.induvidualSensors')}</h2>

			{reportSensors
				.sort(sortSensors)
				.reduce(
					(sum, cur) => {
						if (sum[sum.length - 1].length < 2) sum[sum.length - 1].push(cur);
						else sum.push([cur]);
						return sum;
					},
					[[]]
				)
				.map((senCouple, rowI) => (
					<div key={senCouple[0]?.sensorid || 0}>
						<div className='page-break' style={{ display: rowI && rowI % 4 === 0 ? '' : 'none' }} />

						<div style={{ display: 'flex', justifyContent: 'center' }}>
							{senCouple.map(sen => (
								<div key={sen.sensorid}>
									<h3
										style={{
											width: '27rem',
											height: '3.1rem',
											margin: '1.0rem 0 0 1.0rem',
											color: '#444',
											fontSize: '115%',
											fontWeight: '400',
											overflow: 'hidden',
										}}
									>
										{`${sen.name}${sen.unit?.trim() ? ` (${sen.unit})` : ''}, ${sen.street}, ${sen.city}`}
									</h3>
									<div
										style={{
											width: '28rem',
											height: '11rem',
										}}
									>
										<HistoryLineChart
											data={[
												{
													id: sen.sensorid,
													data:
														sen.data
															?.filter(dat => !isNaN(dat.unitVal) && dat.unitVal !== null)
															.map(dat => ({
																x: new Date(dat.date).toISOString(),
																y: dat.unitVal,
															})) || [],
												},
											]}
											yRange={(() => {
												const senGrp = reportSenGrps[getSensorType(sen)];
												if (!senGrp) return;
												const displayRange = getDataDisplayRange(senGrp);
												return [displayRange.min, displayRange.max];
											})()}
											maxLegends={0}
											margin={{ right: 35 }}
											sensorInfo={{
												lowerthreshold: sen.lowerthreshold,
												upperthreshold: sen.upperthreshold,
												lowerthresholdLabel: t('document.lower'),
												upperthresholdLabel: t('document.upper'),
											}}
											timeOptions={interval}
											chartProps={chartProps}
											selectedSensor={true}
										/>
									</div>
								</div>
							))}
						</div>
					</div>
				))}
		</>
	);
}
