import React, { useState } from 'react';
import { useQuery, useLazyQuery, gql } from '@apollo/client';
import { format as _formatDate, differenceInDays, differenceInMinutes, addMinutes, addDays, set } from 'date-fns';
import { CheckCircle as PositiveStatusIcon, RemoveCircle as NegativeStatusIcon } from '@material-ui/icons';
import {
	UNIT_CLASSIFICATIONS,
	INTERVAL_VALUES,
	MINUTES_PER_WEEK,
	MINUTES_PER_MONTH,
	MINUTES_PER_YEAR,
} from '../connect-front/src/constants';
import * as connectFrontColors from '../connect-front/src/colors';
import { getFormattedNumber } from '../connect-front/src/utility-functions';
import i18n from '../i18n';
import { BODY_PROPS, REPORT_OPTIONS } from '../constants';
import Header from './Header';
import AnalysisTabs from './AnalysisTabs';
import { useTranslation } from 'react-i18next';
import OverviewPage from './OverviewPage';
import ComparePage from './ComparisonPage';
import Table from './Table';
import AiPage from './AiPage';
import { GET_AI_CONTROL } from '../connect-front/src/utility-functions/gqlQueries';
import ReportSensors from './ReportSensors';

const GET_SENSORS_AND_PROPERTIES = gql`
	query ($sensorLocationFilter: SensorLocationFilter, $sensorViewFilter: SensorViewFilter) {
		getSensorLocations(filter: $sensorLocationFilter) {
			locationid
			city
			street
			cadastral
		}
		getSensorView(filter: $sensorViewFilter) {
			sensorid
			name
			unit
			classification
			lowerthreshold
			upperthreshold
			locationid
			city
			street
			cadastral
			groupname
			multiplier
		}
	}
`;

const GET_INCLUDED_REPORT_SENSOR_DATA = gql`
	query ($reportSensorDataFilter: SensorDataNewFilter!) {
		getSensorDataNew(filter: $reportSensorDataFilter) {
			sensorid
			sensordata {
				x
				y
			}
		}
	}
`;

const GET_ENERGY_SENSOR_DATA = gql`
	query ($energySensorDataFilter: SensorDataNewFilter!) {
		getSensorDataNew(filter: $energySensorDataFilter) {
			sensorid
			sensordata {
				x
				y
			}
		}
	}
`;

const GET_ALARM_AND_CONFIRMS_DATA = gql`
	query ($alarmLogFilter: AlarmLogFilter, $confirmFilter: ConfirmFilter) {
		getAlarmLogView(filter: $alarmLogFilter) {
			sensorid
			timestamp
			lowerthreshold
			upperthreshold
			sensorvalue
			name
			unit
			classification
			city
			street
			locationid
			multiplier
		}
		getConfirms(filter: $confirmFilter) {
			timestamp
			sensorid
		}
	}
`;
const ENERGY_DATA_DELAY_DAYS = 3;

const START_DATE_RAW = new URLSearchParams(window.location.search).get('start') || '';
const END_DATE_RAW = new URLSearchParams(window.location.search).get('end') || '';
const START_DATE_STR = START_DATE_RAW.length >= 19 ? START_DATE_RAW : START_DATE_RAW + 'T00:00:00'.slice(START_DATE_RAW.length - 10);
const END_DATE_STR = END_DATE_RAW.length >= 19 ? END_DATE_RAW : END_DATE_RAW + 'T00:00:00'.slice(END_DATE_RAW.length - 10);
const START_DATE = new Date(START_DATE_STR);
const END_DATE = new Date(END_DATE_STR);

const INTERVAL_DIFF_MINUTES = differenceInMinutes(END_DATE, START_DATE);
const ENERGY_DATA_EXISTS = differenceInDays(new Date(), START_DATE) >= ENERGY_DATA_DELAY_DAYS;
const ENERGY_DATA_OFFSET_DAYS = Math.max(0, ENERGY_DATA_DELAY_DAYS - differenceInDays(new Date(), END_DATE));
const INTERVAL_DAYS_FLOAT = differenceInMinutes(END_DATE, START_DATE) / (60 * 24);
const INTERVAL_DAYS = Math.round(INTERVAL_DAYS_FLOAT);
const INTERVAL = {
	...(INTERVAL_DAYS <= 1
		? INTERVAL_VALUES.day
		: INTERVAL_DAYS < 7
			? {
				...INTERVAL_VALUES.day,
				label: INTERVAL_DAYS + i18n.t('document.daysPreSpace'),
				days: INTERVAL_DAYS,
				intervalHours: INTERVAL_DAYS <= 2 ? 2 : INTERVAL_DAYS <= 3 ? 3 : INTERVAL_DAYS <= 4 ? 4 : 6,
				dateFormat: 'HH:mm (d)',
			}
			: INTERVAL_DAYS === 7
				? INTERVAL_VALUES.week
				: INTERVAL_DAYS >= 28 && INTERVAL_DAYS <= 31
					? INTERVAL_VALUES.month
					: {
						...INTERVAL_VALUES.month,
						label: INTERVAL_DAYS + i18n.t('document.daysPreSpace'),
						intervalHours: INTERVAL_DAYS < 28 ? 24 : Math.round((24 * INTERVAL_DAYS_FLOAT) / 28),
					}),
	start: START_DATE,
	end: END_DATE,
	days: INTERVAL_DAYS_FLOAT,
};
if (INTERVAL.intervalHours.lineChart) INTERVAL.intervalHours = INTERVAL.intervalHours.lineChart;

function getIntervalFromMinutes(intervalInMinutes) {
	if (intervalInMinutes < MINUTES_PER_WEEK * 2) {
		return '1 hour';
	}
	if (intervalInMinutes < MINUTES_PER_MONTH * 4) {
		return '1 day';
	}
	if (intervalInMinutes < MINUTES_PER_YEAR * 3) {
		return '1 month';
	}
	return '1 year';
}
const DATA_INTERVAL = getIntervalFromMinutes(INTERVAL_DIFF_MINUTES);

function getDataWithPrice(data, classificationId, energyPrices = {}, multiplier = 1) {
	let priceMultip = 1;
	if (classificationId === UNIT_CLASSIFICATIONS.electricity.id)
		priceMultip = energyPrices?.[UNIT_CLASSIFICATIONS.electricity.energyIndex] || UNIT_CLASSIFICATIONS.electricity.priceMultiplier;
	else if (classificationId === UNIT_CLASSIFICATIONS.water.id)
		priceMultip = energyPrices?.[UNIT_CLASSIFICATIONS.water.energyIndex] || UNIT_CLASSIFICATIONS.water.priceMultiplier;
	else if (classificationId === UNIT_CLASSIFICATIONS.districtHeating.id)
		priceMultip =
			energyPrices?.[UNIT_CLASSIFICATIONS.districtHeating.energyIndex] || UNIT_CLASSIFICATIONS.districtHeating.priceMultiplier;

	return data.map(datum => {
		return {
			date: new Date(datum.x),
			// Multiply by intervalHours to ensure that price changes according to the selected timeframe
			// value -> priceVal
			// if average use next line
			// priceVal: datum.y ? datum.y * INTERVAL.intervalHours * priceMultip * (multiplier || 1) : datum.y, // Don't multiply when y is null as it becomes 0
			priceVal: datum.y ? datum.y * (multiplier || 1) * priceMultip : datum.y,
			// rawValue -> unitVal
			unitVal: datum.y && datum.y * (multiplier || 1),
		};
	});
}

function formatDate(date, format) {
	return _formatDate(new Date(date), format || 'yyyy-MM-dd HH:mm');
}

function getSensorType(sensor) {
	return (sensor.name || '' + sensor.unit || '').replace(/\W/g, '').toLowerCase(); // \W is all non-alphanumeric characters
}

// Example usage
const LOADING_FINISHED_MSG = 'LOADING_FINISHED';

const graphHeaderStyle = { margin: '1.0rem 0 0.5rem 1.2rem', color: '#333', fontSize: '155%', fontWeight: '500' };

export default function DocumentTwo(props) {
	function shouldDrawPage(drawOption) {
		return props.user?.reportOptions?.includes(drawOption) || false;
	}
	const drawOverviewPage = shouldDrawPage(REPORT_OPTIONS.drawOverviewPage) && ENERGY_DATA_EXISTS;
	const drawEnergyPage = shouldDrawPage(REPORT_OPTIONS.drawEnergyDataPage) && ENERGY_DATA_EXISTS;
	const drawEleComPage = shouldDrawPage(REPORT_OPTIONS.drawElectricityComparisonPage) && ENERGY_DATA_EXISTS;
	const drawWaterComPage = shouldDrawPage(REPORT_OPTIONS.drawWaterComparisonPage) && ENERGY_DATA_EXISTS;
	const drawDistCompPage = shouldDrawPage(REPORT_OPTIONS.drawDistrictHeatingComparisonPage) && ENERGY_DATA_EXISTS;
	const drawChartPage = shouldDrawPage(REPORT_OPTIONS.drawChartPage);
	const drawAlarmPage = shouldDrawPage(REPORT_OPTIONS.drawAlarmPage);
	const drawAiPage = shouldDrawPage(REPORT_OPTIONS.drawAiPage) && ENERGY_DATA_EXISTS;

	const [loadingState, setLoadingState] = useState({
		hasAlarms: !drawAlarmPage,
		hasOverview: !drawOverviewPage,
		hasAi: !drawAiPage,
		hasEleCom: !drawEleComPage,
		hasWaterCom: !drawWaterComPage,
		hasDistCom: !drawDistCompPage,
		hasReport: !Boolean(props.user?.reportSensors?.length),
		hasEnergy: !(drawOverviewPage || drawEnergyPage),
		hasChart: !drawChartPage,
	});

	const [properties, setProperties] = useState([]);
	const [sensors, setSensors] = useState([]);
	const [energySensors, setEnergySensors] = useState([]);
	const [reportSensors, setReportSensors] = useState([]);
	const [alarmLogs, setAlarmLogs] = useState([]);
	const [energyConsumptionCosts, setEnergyConsumptionCosts] = useState({ electricity: 0, water: 0, districtHeating: 0 });
	const { t } = useTranslation();

	function addDataKey(sensorData, sensorView) {
		const dataToChange = [...sensorView];
		let senIdx;
		for (const sensor of sensorData) {
			senIdx = dataToChange.findIndex(sen => Number(sen.sensorid) === sensor.sensorid);
			const newSensor = { ...dataToChange[senIdx] };
			newSensor.data = getDataWithPrice(sensor.sensordata, newSensor.classification, props.user?.energyPrices, newSensor.multiplier);
			dataToChange[senIdx] = newSensor;
		}
		return dataToChange;
	}
	const [aiControls, setAiControls] = useState([]);
	const { loading, error, data } = useQuery(GET_AI_CONTROL, {
		skip: !drawAiPage,
		variables: {
			filter: {
				locationid: props.user?.reportProperties.map(p => String(p)),
			},
		},
		onCompleted: ({ getAiControls: data }) => {
			const unique_locations = [...new Set(data.map(d => d.locationid))];
			let temp_ai_controls = [];
			for (let locationid of unique_locations) {
				let location_ai_controls = data.filter(d => d.locationid == locationid);
				const unique_systems = [...new Set(location_ai_controls.map(d => d.system))];
				for (let system of unique_systems) {
					let system_ai_controls = location_ai_controls.filter(d => d.system == system);
					temp_ai_controls.push(system_ai_controls);
				}
			}
			setAiControls(temp_ai_controls);
			setTimeout(() => setLoadingState(curr => ({ ...curr, hasAi: true })), 15000);
		},
	});

	const [getIncludedSensorData] = useLazyQuery(GET_INCLUDED_REPORT_SENSOR_DATA, {
		onCompleted: ({ getSensorDataNew: sensorData }) => {
			const newReportSensors = addDataKey(sensorData, reportSensors);
			setReportSensors(newReportSensors);
			setLoadingState(curr => ({ ...curr, hasReport: true }));
		},
	});
	const [getEnergySensorData] = useLazyQuery(GET_ENERGY_SENSOR_DATA, {
		onCompleted: ({ getSensorDataNew: sensorData }) => {
			const updatedEnergySensors = addDataKey(sensorData, energySensors);
			setEnergySensors(updatedEnergySensors);

			const getTotalCostForClassification = (sensors, classificationId) => {
				const classSensors = sensors?.filter(sen => sen.classification === classificationId) || [];
				return classSensors.reduce(
					(totalCost, sen) =>
						totalCost +
						(sen.data || []).reduce((sensorCost, datum) => {
							return sensorCost + datum.priceVal;
						}, 0),
					0
				);
			};

			setEnergyConsumptionCosts({
				electricity: getTotalCostForClassification(updatedEnergySensors, UNIT_CLASSIFICATIONS.electricity.id),
				water: getTotalCostForClassification(updatedEnergySensors, UNIT_CLASSIFICATIONS.water.id),
				districtHeating: getTotalCostForClassification(updatedEnergySensors, UNIT_CLASSIFICATIONS.districtHeating.id),
			});
			setLoadingState(curr => ({ ...curr, hasEnergy: true, hasOverview: true }));
		},
	});
	const [getEnergyCompareData] = useLazyQuery(GET_ENERGY_SENSOR_DATA, {
		onCompleted: ({ getSensorDataNew: sensorData }) => {
			// Add electricityComparison-key to sensor arrays
			const newEnergySensors = [...energySensors];
			for (const resSen of sensorData) {
				const senInd = newEnergySensors.findIndex(sen => Number(sen.sensorid) === resSen.sensorid);
				if (senInd !== -1) newEnergySensors[senInd] = { ...newEnergySensors[senInd], eleComData: resSen.sensordata };
			}
			setEnergySensors(newEnergySensors);
			setLoadingState(curr => ({ ...curr, hasEleCom: true, hasWaterCom: true, hasDistCom: true }));
		},
	});
	const [getAlarmAndConfirmData] = useLazyQuery(GET_ALARM_AND_CONFIRMS_DATA, {
		onCompleted: res => {
			const allLogs = [...(res.getAlarmLogView || [])];
			const allCons = [...(res.getConfirms || [])];

			const latestLogs = [];
			for (const log of allLogs.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1)))
				if (!latestLogs.find(latestL => latestL.sensorid === log.sensorid)) latestLogs.push(log);
			const activeAlarms = latestLogs.filter(
				log => !allCons.find(con => con.sensorid === log.sensorid && con.timestamp > log.timestamp)
			);

			setAlarmLogs([
				...activeAlarms.map(ala => {
					const latestConTimestamp =
						allCons.filter(con => con.sensorid === ala.sensorid).sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))[0]
							?.timestamp || '2000';
					const logs = allLogs
						.filter(allL => allL.sensorid === ala.sensorid && allL.timestamp > latestConTimestamp)
						.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));
					return {
						...ala,
						worstValue: (() => {
							const worst = { value: undefined, offset: Number.NEGATIVE_INFINITY };
							for (const log of logs) {
								const offset =
									log.sensorvalue <= log.lowerthreshold
										? log.lowerthreshold - log.sensorvalue
										: log.sensorvalue - log.upperthreshold;
								if (offset > worst.offset) {
									worst.value = log.sensorvalue;
									worst.offset = offset;
								}
							}
							return `${getFormattedNumber(worst.value * (ala.multiplier || 1)) || ''} ${ala.unit || ''}`;
						})(),
						thresholds:
							(ala.lowerthreshold !== undefined ? getFormattedNumber(ala.lowerthreshold * (ala.multiplier || 1)) : '-') +
							' : ' +
							(ala.upperthreshold !== undefined ? getFormattedNumber(ala.upperthreshold * (ala.multiplier || 1)) : '-'),
						earliestAlarm: formatDate(logs[0].timestamp),
						latestAlarm: logs.length > 1 ? formatDate(ala.timestamp) : '-',
					};
				}),

				...allCons
					.map(con => ({
						...(sensors.find(sen => String(sen.sensorid) === String(con.sensorid)) || {}),
						...con,
						logs: (() => {
							const earlierConTimestamp = allCons.reduce(
								(sum, cur) =>
									cur.sensorid === con.sensorid && cur.timestamp > sum && cur.timestamp < con.timestamp
										? cur.timestamp
										: sum,
								'2000'
							);
							return allLogs
								.filter(
									log =>
										log.sensorid === con.sensorid &&
										log.timestamp > earlierConTimestamp &&
										log.timestamp < con.timestamp
								)
								.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));
						})(),
					}))
					.filter(con => new Date(con.timestamp) < END_DATE && con.locationid && con.logs.length)
					.map(con => {
						const lastLog = con.logs[con.logs.length - 1];
						return {
							...con,
							fixed: true,
							worstValue: (() => {
								const worst = { value: undefined, offset: Number.NEGATIVE_INFINITY };
								for (const log of con.logs) {
									const offset =
										log.sensorvalue <= log.lowerthreshold
											? log.lowerthreshold - log.sensorvalue
											: log.sensorvalue - log.upperthreshold;
									if (offset > worst.offset) {
										worst.value = log.sensorvalue;
										worst.offset = offset;
									}
								}
								return `${getFormattedNumber(worst.value * (con.multiplier || 1)) || ''} ${con.unit || ''}`;
							})(),
							thresholds:
								(lastLog.lowerthreshold !== undefined
									? getFormattedNumber(lastLog.lowerthreshold * (lastLog.multiplier || 1))
									: '-') +
								' : ' +
								(lastLog.upperthreshold !== undefined
									? getFormattedNumber(lastLog.upperthreshold * (lastLog.multiplier || 1))
									: '-'),
							earliestAlarm: formatDate(con.logs[0].timestamp),
							latestAlarm: con.logs.length > 1 ? formatDate(lastLog.timestamp) : '-',
						};
					}),
			]);
			setLoadingState(curr => ({ ...curr, hasAlarms: true }));
		},
	});

	useQuery(GET_SENSORS_AND_PROPERTIES, {
		variables: {
			sensorLocationFilter: { locationids: props.user.reportProperties || [] },
			sensorViewFilter: { locationids: props.user.reportProperties || [] },
		},
		onCompleted: res => {
			const rp = props.user.reportProperties;
			const properties = (rp ? res.getSensorLocations.filter(sen => rp.includes(sen.locationid)) : res.getSensorLocations) || [];
			const sensors = res.getSensorView || [];
			let energySensors =
				res.getSensorView.filter(
					sen =>
						sen.classification === UNIT_CLASSIFICATIONS.electricity.id ||
						sen.classification === UNIT_CLASSIFICATIONS.water.id ||
						sen.classification === UNIT_CLASSIFICATIONS.districtHeating.id
				) || [];

			if (rp) {
				energySensors = energySensors.filter(sen => rp.includes(sen.locationid));
			}
			// Convert report sensor IDs to strings before filtering
			const reportSensorIds = props.user.reportSensors.map(id => id.toString());
			const reportSensors = res.getSensorView?.filter(sen => reportSensorIds.includes(sen.sensorid)) || [];
			setProperties(properties);
			setSensors(sensors);
			setEnergySensors(energySensors);
			setReportSensors(reportSensors);
			if (props.user?.reportSensors?.length) {
				getIncludedSensorData({
					variables: {
						reportSensorDataFilter: {
							sensorids: reportSensors.map(sen => Number(sen.sensorid)),
							timestamp_gte: START_DATE,
							timestamp_lte: END_DATE,
							timestamp_interval: getIntervalFromMinutes(INTERVAL_DIFF_MINUTES),
							interval_type: 'mean',
						},
					},
				});
			}
			if (drawOverviewPage || drawEnergyPage || drawEleComPage || drawWaterComPage || drawDistCompPage) {
				getEnergySensorData({
					variables: {
						energySensorDataFilter: {
							sensorids: energySensors.map(sen => Number(sen.sensorid)),
							timestamp_gte: addDays(START_DATE, -ENERGY_DATA_OFFSET_DAYS),
							timestamp_lte: addDays(END_DATE, -ENERGY_DATA_OFFSET_DAYS),
							timestamp_interval: '1 day', //getIntervalFromMinutes(INTERVAL_DIFF_MINUTES)'',
							interval_type: 'sum',
						},
					},
				});
			}

			if (drawEleComPage || drawWaterComPage || drawDistCompPage) {
				getEnergyCompareData({
					variables: {
						energySensorDataFilter: {
							sensorids: energySensors.map(sen => Number(sen.sensorid)),
							timestamp_gte: addDays(
								addMinutes(START_DATE, -differenceInMinutes(END_DATE, START_DATE)),
								-ENERGY_DATA_OFFSET_DAYS
							),
							timestamp_lte: addDays(END_DATE, -ENERGY_DATA_OFFSET_DAYS),
							timestamp_interval: `${differenceInMinutes(END_DATE, START_DATE)} minutes`, // +2 to avoid a third data-point being generated
							interval_type: 'sum',
						},
					},
				});
			}
			if (drawAlarmPage) {
				getAlarmAndConfirmData({
					variables: {
						alarmLogFilter: {
							locationids: rp || props.user.propertyIds || [],
							startdate: START_DATE,
							enddate: END_DATE,
							locationids: rp || props.user.propertyIds,
						},
						confirmFilter: {
							startdate: START_DATE,
							enddate: END_DATE,
							sensorids: sensors.map(sen => Number(sen.sensorid)),
						},
					},
				});
			}
		},
	});

	const reportSenGrps = {};
	for (const sen of reportSensors) {
		const validData = sen.data?.map(dat => dat.unitVal).filter(dat => dat !== null);
		if (!validData?.length) continue;

		const senCode = getSensorType(sen);
		const extremes = {
			min: validData.reduce((res, cur) => Math.min(res, cur), validData[0]),
			max: validData.reduce((res, cur) => Math.max(res, cur), validData[0]),
		};
		if (reportSenGrps[senCode] === undefined) reportSenGrps[senCode] = extremes;
		else {
			reportSenGrps[senCode].min = Math.min(reportSenGrps[senCode].min, extremes.min);
			reportSenGrps[senCode].max = Math.max(reportSenGrps[senCode].max, extremes.max);
		}
	}

	if (Object.values(loadingState).every(val => val)) {
		setTimeout(() => console.log(LOADING_FINISHED_MSG), 3000);
	}

	const canDraw = {
		overview: drawOverviewPage,
		energy: false,
		eleCom:
			drawEleComPage &&
			loadingState.hasEleCom &&
			energySensors.some(sen => sen.classification === UNIT_CLASSIFICATIONS.electricity.id),
		waterCom:
			drawWaterComPage && loadingState.hasWaterCom && energySensors.some(sen => sen.classification === UNIT_CLASSIFICATIONS.water.id),
		distCom:
			drawDistCompPage &&
			loadingState.hasDistCom &&
			energySensors.some(sen => sen.classification === UNIT_CLASSIFICATIONS.districtHeating.id),
		reportSensors: reportSensors.length,
		chart: drawChartPage,
		alarm: drawAlarmPage && alarmLogs.length,
		ai: drawAiPage,
	};

	const pagesToDraw = [
		{
			pageId: 'overview',
			page: (
				<OverviewPage
					{...{
						props,
						energySensors,
						properties,
						alarmLogs,
						energyConsumptionCosts,
						INTERVAL,
						reportSensors,
					}}
				/>
			),
		},
		{
			pageId: 'ai',
			page: <AiPage properties={properties || []} aiControls={aiControls} interval={INTERVAL} />,
		},
		{
			pageId: 'eleCom',
			page: (
				<ComparePage
					chartTitle={t('document.electricityComparison')}
					tableTitle={t('document.energyComparisonPerBuilding')}
					sensorType='electricity'
					bodyWidth={BODY_PROPS.width}
					graphHeaderStyle={graphHeaderStyle}
					energySensors={energySensors}
				/>
			),
		},
		{
			pageId: 'waterCom',
			page: (
				<ComparePage
					chartTitle={t('document.m3PerProperty')}
					tableTitle={t('document.waterComparisonPerBuilding')}
					sensorType='water'
					bodyWidth={BODY_PROPS.width}
					graphHeaderStyle={graphHeaderStyle}
					energySensors={energySensors}
				/>
			),
		},
		{
			pageId: 'distCom',
			page: (
				<ComparePage
					chartTitle={t('document.districtHeatingkwhPerBuilding')}
					tableTitle={t('document.districtHeatingkwhPerBuilding')}
					sensorType='districtHeating'
					bodyWidth={BODY_PROPS.width}
					graphHeaderStyle={graphHeaderStyle}
					energySensors={energySensors}
				/>
			),
		},
		{
			pageId: 'reportSensors',
			page: (
				<ReportSensors
					reportSenGrps={reportSenGrps}
					graphHeaderStyle={graphHeaderStyle}
					reportSensors={reportSensors}
					getSensorType={getSensorType}
					interval={INTERVAL}
				/>
			),
		},
		{
			pageId: 'chart',
			page: (
				<AnalysisTabs
					userId={props.user.id}
					startDate={START_DATE}
					endDate={END_DATE}
					loadingCallback={value => {
						setLoadingState(curr => ({ ...curr, hasChart: value }));
					}}
				/>
			),
		},
		{
			pageId: 'alarm',
			page: (
				<>
					<h2 style={{ ...graphHeaderStyle, marginBottom: '-1rem', marginLeft: '2.4rem', fontSize: '160%' }}>
						{t('document.alarms')}
					</h2>
					<Table
						data={alarmLogs.sort((a, b) => (new Date(a.earliestAlarm) < new Date(b.earliestAlarm) ? -1 : 1))}
						tableProps={{
							columns: [
								{
									title: t('document.acknowledged'),
									field: 'fixed',
									render: row =>
										row.fixed ? (
											<PositiveStatusIcon style={{ padding: '0.15rem 0 0 0', color: '#2e7d32' }} />
										) : (
											<NegativeStatusIcon color='error' style={{ padding: '0.15rem 0 0 0' }} />
										),
								},
								{ title: t('generic.name'), field: 'name', maxLength: 24, truncateLeft: true },
								{ title: t('document.alarmValue'), field: 'worstValue' },
								{ title: t('document.alarmThresholds'), field: 'thresholds' },
								{ title: t('document.previousAlarms'), field: 'earliestAlarm' },
								{ title: t('document.latestAlarm'), field: 'latestAlarm' },
								{ title: t('generic.address'), field: 'street', truncateLeft: true },
								{ title: t('generic.city'), field: 'city' },
							],
							maxColumnLength: 16,
						}}
					/>
				</>
			),
		},
	].filter(({ pageId }) => canDraw[pageId]);

	return (
		<div style={{ background: connectFrontColors.websiteBg }}>
			<Header
				interval={INTERVAL}
				startDate={START_DATE_STR}
				endDate={END_DATE_STR}
				customer={props.user?.customerName?.toLowerCase() || 'meliox'}
			/>

			<div style={{ width: BODY_PROPS.width, margin: '0 auto' }}>
				{pagesToDraw.map(({ page, pageId }, i) => (
					<React.Fragment key={'page-' + pageId}>
						{i !== 0 ? <div className='page-break' /> : undefined}
						{page}
					</React.Fragment>
				))}

				{/* Draw analysis page */}

				{/* Extend last page to bottom so background-color shows all the way down */}
				<div style={{ pageBreakBefore: 'always' }} />
			</div>
		</div>
	);
}
