import React, { useEffect, useRef, useState } from 'react';
import { useQuery, useLazyQuery, gql } from '@apollo/client';
import { Grid } from '../connect-front/src/components/charts/Widget/WidgetGrid';
import { createWidget } from '../connect-front/src/components/charts/Widget/WidgetFactory';
import { CHART_WIDGET_TYPES } from '../connect-front/src/constants';
import { WidgetsRounded } from '@material-ui/icons';

const GET_CHARTS = gql`
	query ($userId: String) {
		getChartList(userId: $userId)
	}
`;

const GET_TABS = gql`
	query ($chartIds: [Int!]!) {
		getTabList(chartIds: $chartIds) {
			id
			chartid
			tabindex
			tabname
			includeinreport
			updateinterval
			timerange
			timestart
			timeend
			properties {
				locationid
				city
				street
			}
		}
	}
`;

const GET_WIDGETS = gql`
	query ($tabIds: [Int!]!) {
		getWidgetListMultiple(tabIds: $tabIds) {
			id
			charttabid
			rowindex
			columnindex
			includeinreport
			height
			width
			minheight
			minwidth
			maxheight
			maxwidth
			widgetmetadata
			sensors {
				sensorid
				locationid
			}
			widgetname
		}
	}
`;

const graphHeaderStyle = { margin: '1.0rem 0 0.5rem 1.2rem', color: '#333', fontSize: '155%', fontWeight: '500' };

function AnalysisTabs({ userId, loadingCallback, startDate, endDate }) {
	const [loadedGridCounter, setLoadedGridCounter] = useState([]);

	const [getTabs, { data: analysisTabData, error: tabsError }] = useLazyQuery(GET_TABS, {
		onCompleted: tabData => {
			if (!tabData.getTabList.filter(({ includeinreport }) => includeinreport).length) {
				loadingCallback(true);
			}
		},
		fetchPolicy: 'no-cache',
	});

	const { error: chartError } = useQuery(GET_CHARTS, {
		variables: { userId: userId },
		onCompleted: analysisChartData => {
			getTabs({
				variables: {
					chartIds: analysisChartData.getChartList,
				},
			});
		},
		fetchPolicy: 'no-cache',
	});

	const includedTabs = analysisTabData?.getTabList.filter(({ includeinreport }) => includeinreport);

	const gridDoneLoading = id => {
		setLoadedGridCounter(curr => [...curr, id]);
	};

	useEffect(() => {
		if (new Set(loadedGridCounter).size === includedTabs?.length) {
			const timer = setTimeout(() => {
				loadingCallback(true);
			}, 200);
			return () => clearTimeout(timer);
		}
	}, [loadedGridCounter]);

	useEffect(() => {
		if (tabsError || chartError) {
			loadingCallback(true);
		}
	}, [tabsError, chartError]);

	return (
		<>
			{![undefined, 0].includes(includedTabs?.length)
				? includedTabs.map((tab, index) => (
						<div key={`includetab-${index}`}>
							<h2 style={{ ...graphHeaderStyle, marginLeft: '3.0rem', marginBottom: '-0.5rem' }}>{tab.tabname}</h2>
							<IncludedTab
								index={index}
								tabID={tab.id}
								gridDoneLoadingCallback={gridDoneLoading}
								{...{ startDate, endDate }}
							/>
							{index !== includedTabs.length - 1 ? <div className='page-break' /> : undefined}
						</div>
				  ))
				: undefined}
		</>
	);
}

function IncludedTab({ index, tabID, gridDoneLoadingCallback, startDate, endDate }) {
	const [widgets, setWidgets] = useState([]);
	const [widgetsDoneLoading, setWidgetsDoneLoading] = useState([]);
	const gridRef = useRef();

	const widgetLoaded = id => {
		setWidgetsDoneLoading(curr => [...curr, id]);
	};

	const { data: getWidgetData, error: widgetError } = useQuery(GET_WIDGETS, {
		variables: {
			tabIds: tabID,
		},
		onCompleted: ({ getWidgetListMultiple: widgetData }) => {
			const newWidgets = widgetData.map(widget => ({
				key: `widget-${widget.id}`,
				id: widget.id,
				element: widget.widgetname,
				includeinreport: widget.includeinreport,
				props: widget.widgetmetadata,
				sensors: widget.sensors.map(sensor => ({ ...sensor, sensorid: parseInt(sensor.sensorid) })),
				options: {
					w: widget.width,
					h: widget.height,
					x: widget.columnindex,
					y: widget.rowindex,
					minW: widget.minwidth,
					maxW: widget.maxwidth,
					minH: widget.minheight,
					maxH: widget.maxheight,
				},
			}));
			setWidgets(newWidgets);
			if (!newWidgets.length) {
				gridDoneLoadingCallback(tabID);
			}
		},
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (new Set(widgetsDoneLoading).size === widgets.length && getWidgetData?.getWidgetListMultiple !== undefined) {
			gridDoneLoadingCallback(tabID);
		}
	}, [widgetsDoneLoading]);

	useEffect(() => {
		if (widgetError) {
			gridDoneLoadingCallback(tabID);
		}
	}, [widgetError]);

	const getWidgetComponents = () =>
		widgets.map(({ key, element, sensors, props, options, id }) => {
			const timeRange = {
				startDate: new Date(startDate),
				endDate: new Date(endDate),
				offset: undefined,
				selectedOption: 'custom',
			};

			return {
				id: key,
				options: options,
				content: createWidget(element, {
					widgetKey: key,
					id,
					sensors,
					props,
					options,
					changeSensorCallback: () => {},
					properties: [],
					currentDatetimeRange: { ...timeRange },
					currentAutoUpdateInterval: 0,
					updateChildTitle: () => {},
					normalize: false,
					editMode: false,
					dataLoadedCallback: () => widgetLoaded(id),
					hidePopoutIcon: true,
					animation: false,
					viewMode: 'report',
				}),
			};
		});

	return (
		<>
			{widgets.length ? (
				<>
					<div style={{ height: '95%', width: '95%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
						<Grid
							gridRef={gridRef}
							id={index}
							widgets={getWidgetComponents()}
							editMode={false}
							deleteWidgetCallback={() => {}}
						/>
					</div>
				</>
			) : undefined}
		</>
	);
}

export default AnalysisTabs;
